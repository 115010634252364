<template>
  <v-row no-gutters class="moncompte__layout flex-nowrap" v-if="mounted">
    <v-col
      class="col-lg-3 col-md-3"
      style="height: 100%; min-width: 380px !important;"
    >
      <v-col
        cols="12"
        class="moncompte__navigation-sidebar-avatar px-1 py-3 my-4"
        style="min-width: 380px !important;"
      >
        <Avatar
          :context="{ ID_Utilisateur: currentUserId }"
          size="102"
          upload
          labelButton="Changer ma photo"
        />
      </v-col>

      <v-col
        cols="12"
        class="moncompte__navigation-sidebar-info-users px-1 py-3"
      >
        <BasicInfoUser
          :context="{
            ID_Utilisateur: currentUserId,
            ID_Saison: currentSaison.id,
          }"
        />
      </v-col>
    </v-col>

    <v-col class="col-lg-9 col-md-7">
      <div class="moncompte__title-section">{{ title_section }}</div>
      <div>
        <div class="my-1">
          <div class="d-flex align-center justify-space-around">
            <div
              class="card-item"
              @click.prevent="setActiveItem('Modifier mes coordonnées')"
            >
              <span class="cart-text text-center"
                >Modifier mes coordonnées
              </span>
              <div
                v-if="isItemActive('Modifier mes coordonnées')"
                class="bottom-layer-2"
              />
            </div>
            <div
              class="card-item"
              @click.prevent="setActiveItem('Activités pratiquées')"
            >
              <span class="cart-text text-center">Activités pratiquées</span>
              <div
                v-if="isItemActive('Activités pratiquées')"
                class="bottom-layer-2"
              />
            </div>
            <div
              class="card-item"
              @click.prevent="setActiveItem('certificat-medical')"
              v-if="currentActiveLicence && currentActiveLicence.product.slug !== 'licence_decouverte'"
            >
              <span class="cart-text text-center">Certificats médicaux</span>
              <div
                v-if="isItemActive('certificat-medical')"
                class="bottom-layer-2"
              />
            </div>
            <div
              class="card-item"
              @click.prevent="setActiveItem('Abonnements')"
            >
              <span class="cart-text text-center">Abonnements</span>
              <div v-if="isItemActive('Abonnements')" class="bottom-layer-2" />
            </div>

            <div
              class="card-item"
              @click.prevent="
                setActiveItem('Personne à prévenir en cas d’accident')
              "
            >
              <span class="cart-text text-center" href="#role"
                >Personne à prévenir en cas d’accident</span
              >
              <div
                v-if="isItemActive('Personne à prévenir en cas d’accident')"
                class="bottom-layer-2"
              />
            </div>
            <div
              class="card-item"
              @click.prevent="
                setActiveItem('Modification identifiant mot de passe')
              "
            >
              <span class="cart-text text-center" href="#poste"
                >Modification identifiant mot de passe</span
              >
              <div
                v-if="isItemActive('Modification identifiant mot de passe')"
                class="bottom-layer-2"
              />
            </div>
          </div>
        </div>
        <div class="bottom-layer mb-10" />
      </div>

      <div
        class="moncompte__content-content-view align-self-stretch flex-grow-1"
      >
        <transition name="fade" mode="out-in">
          <router-view @setTitle="setTitle"></router-view>
        </transition>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Avatar from "@/components/Common/Common__Avatar.vue";
// import Navigation from "@/components/MonCompte/MonCompte__Navigation.vue";
import BasicInfoUser from "@/components/User/User__BasicInfo.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: { Avatar, BasicInfoUser },

  data: () => ({
    title_section: "Title Section",
    updateAvatarModalIsOpen: false,
    activeTab: "Modifier mes coordonnées",
    mounted: false,
    licences: null,
  }),

  async created() {
    this.setAppTitle({ title: "Mon compte" });
    // await this.setCurrentStructureFederal();

    this.initRolesAndPermissions();
    this.setActiveTab();

    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("user", ["currentUserId", "currentUser"]),
    ...mapGetters("saisons", ["currentSaison", "getSaison"]),
    ...mapGetters("structure", ["currentStructureId", "structures"]),

    currentActiveLicence() {
      return this.licences.find(
        (lic) => lic.season.id === this.currentSaison.id && new Date(lic.dateEnd) > new Date()
      );
    },
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
    ...mapActions("auth", ["initRolesAndPermissions"]),
    ...mapActions("structure", ["setCurrentStructureFederal"]),
    ...mapActions("utilisateurs", ["getUserBasicInfos", "getLicenceUtilisateur"]),

    async setData() {
      if (this.currentUserId) {
        this.currentUser = this.currentUserId;
      } else {
        let user = await this.getUserBasicInfos(this.currentUserId);
        this.currentUser = user.UTI_Utilisateurs[0];
      }

      this.licences = await this.getLicenceUtilisateur({
        user: this.currentUser.id,
        season: this.currentSaison.id,
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });
    },

    setTitle(title) {
      this.title_section = title;
    },
    setActiveTab() {
      console.log("this.$router.name :>> ", this.$route.name);
      switch (this.$route.name) {
        case "mon_compte__modification_cordonnees":
          return (this.activeTab = "Modifier mes coordonnées");
        case "mon_compte__activitees_pratiques":
          return (this.activeTab = " Activités pratiquées");
        case "mon_compte__certificat_medical":
          return (this.activeTab = "certificat-medical");
        case "mon_compte__abonnements":
          return (this.activeTab = "Abonnements");
        case "mon_compte__personnes_incident":
          return (this.activeTab = "Personne à prévenir en cas d’accident");
        case "mon_compte__mot_de_passe":
          return (this.activeTab = "Modification identifiant mot de passe");
      }
    },
    setActiveItem(menuItem) {
      this.activeTab = menuItem;
      switch (menuItem) {
        case "Modifier mes coordonnées":
          return this.$router.push({
            name: "mon_compte__modification_cordonnees",
          });
        case "Activités pratiquées":
          return this.$router.push({
            name: "mon_compte__activitees_pratiques",
          });
        case "certificat-medical":
          return this.$router.push({ name: "mon_compte__certificat_medical" });
        case "Abonnements":
          return this.$router.push({ name: "mon_compte__abonnements" });
        case "Personne à prévenir en cas d’accident":
          return this.$router.push({ name: "mon_compte__personnes_incident" });
        case "Modification identifiant mot de passe":
          return this.$router.push({ name: "mon_compte__mot_de_passe" });
      }
    },
    isItemActive(menuItem) {
      if (this.activeTab === menuItem) return true;
      return false;
    },
  },
};
</script>

<style lang="scss">
.moncompte__navigation-sidebar {
  background: #e7effd !important;
}
.card-item {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26%;
  height: 170px;
  background: #ffffff;
  border: 0.5px solid #dfe5f9;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  margin: 0px 11px;
}
.cart-text {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
  text-transform: uppercase !important;
}
.bottom-layer {
  background: #b8cefa;
  height: 6px;
  width: 100%;
}
.bottom-layer-2 {
  position: absolute;
  bottom: -6px;
  left: 0px;
  right: 0px;
  background: #b8cefa;
  height: 8px;
  width: 100%;
}
.active-item {
  border-bottom: 8px solid #b8cefa;
  min-width: 380px !important;
}
</style>
